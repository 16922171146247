import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

const Main = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  return (
    <Container isTabletOrMobile={isTabletOrMobile}>
      <ContentContainer isTabletOrMobile={isTabletOrMobile}>
        <Circle isTabletOrMobile={isTabletOrMobile} />
        <InfoContainer isTabletOrMobile={isTabletOrMobile}>
          {!!isBigScreen && <LeftLine />}
          <div>
            <Title isTabletOrMobile={isTabletOrMobile}>Palessit</Title>
            <Text style={{ color: 'white' }}>IT-компания полного цикла</Text>
          </div>

          <div>
            <Text opacity={0.2} isTabletOrMobile={isTabletOrMobile}>
              {'const Palessit = styled.div` display: flex;'}
            </Text>
            <Text opacity={0.6} isTabletOrMobile={isTabletOrMobile}>
              {'color: #112345; color: #112345;'}
            </Text>
            <Text
              isTabletOrMobile={isTabletOrMobile}
            >{`font-family: 'Noto Sans'; font-style: normal;`}</Text>
            <Text isTabletOrMobile={isTabletOrMobile}>
              {'font-weight: 600; font-size: 14px;'}
            </Text>
            <Text isTabletOrMobile={isTabletOrMobile}>
              {'line-height: 130%; width: 100%;'}
            </Text>
            <Text opacity={0.6} isTabletOrMobile={isTabletOrMobile}>
              {'overflow: hidden; white-space: nowrap;'}
            </Text>
            <Text opacity={0.2} isTabletOrMobile={isTabletOrMobile}>
              {'text-overflow: ellipsis;'}
            </Text>
          </div>
          {!!isBigScreen && <RightLine />}
        </InfoContainer>
      </ContentContainer>

      <BottomLine isTabletOrMobile={isTabletOrMobile} />
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  background: #2a2f36;
  display: flex;
  flex-direction: column;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'flex-start' : 'center'};
  overflow: hidden;
  justify-content: space-between;
`

const Circle = styled.div`
  display: flex;
  position: absolute;
  align-self: start;
  margin-top: -50px;
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '250px' : '505px')};
  height: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '250px' : '505px')};
  border-radius: 50%;
  background-color: #12c0dd;
  opacity: 0.5;
  filter: blur(300px);
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-right: 20px;
  box-sizing: border-box;
  max-width: 1400px;
  justify-content: center;
  align-items: center;
`

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '94px' : '94px')};
  flex-direction: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'column' : 'row'};
  align-items: center;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
`

const Title = styled.div`
  font-family: 'JetBrains';
  font-weight: 700;
  font-size: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '44px' : '100px')};
  margin-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '0' : '150px')};
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '44px' : '100px'};
  color: white;
  margin-bottom: 5px;
`
const Text = styled.div`
  font-family: 'JetBrains';
  font-weight: 400;
  font-size: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '12px' : '22px')};
  margin-bottom: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '15px' : '35px'};
  line-height: 29px;
  color: #12c0dd;
  opacity: ${({ opacity }) => opacity};
`

const BottomLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-bottom: 150px;
  box-sizing: border-box;
`

const LeftLine = styled.div`
  display: flex;
  position: fixed;
  width: 1px;
  top: 0;
  margin-left: -75px;
  bottom: 0;
  background-color: #b1c7cb80;
  z-index: 2;
`
const RightLine = styled.div`
  display: flex;
  position: fixed;
  width: 1px;
  top: 0;
  bottom: 0;
  margin-left: 1560px;
  background-color: #b1c7cb80;
  z-index: 2;
`

export default Main
