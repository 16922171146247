import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

const Technologies = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  return (
    <Container id='technologies' isTabletOrMobile={isTabletOrMobile}>
      <ContentContainer isTabletOrMobile={isTabletOrMobile}>
        <Title isTabletOrMobile={isTabletOrMobile}>Технологии</Title>
        <Text isTabletOrMobile={isTabletOrMobile}>
          Современный стек технологий открывает стабильную и быструю разработку
        </Text>
        <TopLine isTabletOrMobile={isTabletOrMobile} />
        <TechnologiesContainer isTabletOrMobile={isTabletOrMobile}>
          <div>
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              Back-end technologies
            </WorkTitle>
            <InfoContainer isTabletOrMobile={isTabletOrMobile}>
              <WorkBlock
                isTabletOrMobile={isTabletOrMobile}
                isBigScreen={isBigScreen}
              >
                <WorkItem
                  image={require('../assets/images/Technologies/node.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Node.js
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/api.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  REST API
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/ts.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  TypeScript
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/sendGrid.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  SendGrid (Twilio)
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/twilio.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Twilio
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/cassandra.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Apache Cassandra
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/azure.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Azure
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/azureBus.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Azure Service Bus
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/azureCosmos.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Azure Cosmos DB
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/elastic.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Elasticsearch
                </WorkItem>
              </WorkBlock>
            </InfoContainer>
          </div>
          <div>
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              Front-end technologies
            </WorkTitle>

            <InfoContainer isTabletOrMobile={isTabletOrMobile}>
              <WorkBlock
                front
                isTabletOrMobile={isTabletOrMobile}
                isBigScreen={isBigScreen}
              >
                <WorkItem
                  image={require('../assets/images/Technologies/react.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  React
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/react.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  React Native
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/expo.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Expo
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/figma.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Figma
                </WorkItem>

                <WorkItem
                  image={require('../assets/images/Technologies/miro.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Miro
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/ae.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Adobe after effects
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/ps.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Adobe Photoshop
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/ai.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Adobe Illustrator
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/geoDb.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  GeoDB
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/weather.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  OpenWeather Map
                </WorkItem>
              </WorkBlock>
            </InfoContainer>
          </div>
        </TechnologiesContainer>
        <BottomLine isTabletOrMobile={isTabletOrMobile} />
        <TechnologiesContainer isTabletOrMobile={isTabletOrMobile}>
          <div>
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              Palessit technologies
            </WorkTitle>
            <InfoContainer isTabletOrMobile={isTabletOrMobile}>
              <WorkBlock
                style={{ flexDirection: 'row', marginBottom: 150 }}
                isTabletOrMobile={isTabletOrMobile}
                isBigScreen={isBigScreen}
              >
                <WorkItem
                  image={require('../assets/images/Technologies/palessit.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Palessit webdev
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/geographity.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  Geographity
                </WorkItem>
                <WorkItem
                  image={require('../assets/images/Technologies/lvgn.png')}
                  isTabletOrMobile={isTabletOrMobile}
                >
                  LVGN
                </WorkItem>
              </WorkBlock>
            </InfoContainer>
          </div>
        </TechnologiesContainer>
      </ContentContainer>
    </Container>
  )
}

const WorkItem = ({ children, image }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
      }}
    >
      <Image src={image} alt='' />
      <Info isTabletOrMobile={isTabletOrMobile}>{children}</Info>
    </div>
  )
}

const Container = styled.div`
  height: 100%;
  background: #2a2f36;
  display: flex;
  flex-direction: column;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'flex-start' : 'center'};
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const TechnologiesContainer = styled.div`
  display: flex;
  flex-direction: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'column' : 'row'};

  width: 100%;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100vw' : '1400px'};
`

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'nowrap' : 'wrap'};
  justify-content: space-between;
  align-items: center;
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '700px')};
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
  overflow-x: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'hidden' : 'visible'};
`

const Title = styled.div`
  font-family: 'JetBrains';
  font-weight: 700;
  margin-top: 94px;
  font-size: 24px;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};

  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '44px' : '50px'};
  color: #12c0dd;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1400px;
`
const WorkBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: ${({ isTabletOrMobile, front }) =>
    isTabletOrMobile ? (front ? '526px' : '426px') : '426px'};
  padding-top: 25px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-bottom: 30px;
  justify-content: space-between;
  width: 100%;
`

const WorkTitle = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #12c0dd;
  margin-bottom: 30px;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
`
const Text = styled.div`
  width: 100%;
  max-width: 1400px;
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: white;
  margin-bottom: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '30px' : '60px'};
  opacity: ${({ opacity }) => opacity};
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
`

const Info = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: white;
  padding-right: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-end'};
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '86px' : '')};
`

const TopLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-bottom: 30px;
`
const BottomLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
`
const Image = styled.img`
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '38px' : '60px')};
  height: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '38px' : '60px')};
  object-fit: cover;
  box-sizing: border-box;
`

export default Technologies
