import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import PersonCard from '../Components/PersonCard'

const team = [
  {
    name: 'Roman D.',
    position: 'Palessit CEO',
    image: require('../assets/images/team/Palessit1.png'),
  },
  {
    name: 'Dima S.',
    position: 'Palessit CEO',
    image: require('../assets/images/team/Palessit2.png'),
  },
  {
    name: 'Andrei',
    position: 'Devops architect',
    image: require('../assets/images/team/Palessit3.png'),
  },
  {
    name: 'Nadya',
    position: 'Lead front-end developer',
    image: require('../assets/images/team/Palessit4.png'),
  },
  {
    name: 'Pavel',
    position: 'Lead back-end developer',
    image: require('../assets/images/team/Palessit5.png'),
  },
  {
    name: 'Albert',
    position: 'Full stack developer',
    image: require('../assets/images/team/Palessit6.png'),
  },
  {
    name: 'Alina',
    position: 'UI/UX designer',
    image: require('../assets/images/team/Palessit7.png'),
  },
  {
    name: 'Yulia',
    position: 'UI/UX designer',
    image: require('../assets/images/team/Palessit8.png'),
  },
  {
    name: 'Ekaterina',
    position: 'UI/UX designer',
    image: require('../assets/images/team/Palessit9.png'),
  },
  {
    name: 'Ilya',
    position: 'Design engineer',
    image: require('../assets/images/team/Palessit10.png'),
  },
  {
    name: 'Nitita',
    position: 'Front-end developer',
    image: require('../assets/images/team/Palessit11.png'),
  },
  {
    name: 'Daria',
    position: 'Front-end developer',
    image: require('../assets/images/team/Palessit12.png'),
  },
  {
    name: 'Ksenia',
    position: 'Back-end developer',
    image: require('../assets/images/team/Palessit13.png'),
  },
  {
    name: 'Olesya',
    position: 'Back-end developer',
    image: require('../assets/images/team/Palessit14.png'),
  },
  {
    name: 'Kirill',
    position: 'Back-end developer',
    image: require('../assets/images/team/Palessit15.png'),
  },
  {
    name: 'Daria',
    position: 'Localizer',
    image: require('../assets/images/team/Palessit16.png'),
  },
  {
    name: 'Ekaterina',
    position: 'Accounting',
    image: require('../assets/images/team/Palessit17.png'),
  },
  {
    name: 'Ekaterina',
    position: 'Accounting',
    image: require('../assets/images/team/Palessit18.png'),
  },
  {
    name: 'Ludmila',
    position: 'It-lawyer',
    image: require('../assets/images/team/Palessit19.png'),
  },
  {
    name: 'Pavel',
    position: 'International lawyer',
    image: require('../assets/images/team/Palessit20.png'),
  },
  {
    name: 'Marius',
    position: 'International investmentsmans and VC',
    image: require('../assets/images/team/Palessit21.png'),
  },
  {
    name: 'Kerim',
    position: 'Consultant',
    image: require('../assets/images/team/Palessit22.png'),
  },
  {
    name: 'Philip',
    position: 'Consultant',
    image: require('../assets/images/team/Palessit23.png'),
  },
  {
    name: 'Anna',
    position: 'Marketing',
    image: require('../assets/images/team/Palessit24.png'),
  },
]

const Team = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <>
      <Container id={'team'} isTabletOrMobile={isTabletOrMobile}>
        <ContentContainer isTabletOrMobile={isTabletOrMobile}>
          <Title isTabletOrMobile={isTabletOrMobile}>Команда Palessit</Title>
          {isTabletOrMobile && (
            <>
              <Image
                isTabletOrMobile={isTabletOrMobile}
                src={require('../assets/images/team.jpg')}
                alt='team'
              />
              <Text isTabletOrMobile={isTabletOrMobile}>
                Команда Palessit, это молодые профессионалы, восходящие звезды,
                те кто сегодня только набирает обороты, те кто своим талантом
                будет освещать будущее мирового IT, специалисты которые своим
                трудолюбием и упорством уже сегодня доказывают, что нет
                невозможного.
              </Text>
            </>
          )}

          <UsersContainer isTabletOrMobile={isTabletOrMobile}>
            {team.map((item, index) => (
              <PersonCard
                key={item.name + index}
                image={item.image}
                name={item.name}
                position={item.position}
              />
            ))}
          </UsersContainer>
          <PartnersTitle>+20 подрядчиков по всему миру</PartnersTitle>
        </ContentContainer>
      </Container>
      <TopLine isTabletOrMobile={isTabletOrMobile} />
      {!isTabletOrMobile && (
        <>
          <ImageContainer isTabletOrMobile={isTabletOrMobile}>
            <div
              style={{
                maxWidth: 695,
                paddingRight: 90,
                boxSizing: 'border-box',
              }}
            >
              <Text isTabletOrMobile={isTabletOrMobile}>
                Команда Palessit, это молодые профессионалы, восходящие звезды,
                те кто сегодня только набирает обороты, те кто своим талантом
                будет освещать будущее мирового IT, специалисты которые своим
                трудолюбием и упорством уже сегодня доказывают, что нет
                невозможного.
              </Text>
            </div>
            <Image
              isTabletOrMobile={isTabletOrMobile}
              src={require('../assets/images/team.jpg')}
              alt='team'
            />
          </ImageContainer>
          <TopLine
            style={{ marginBottom: 150 }}
            isTabletOrMobile={isTabletOrMobile}
          />
        </>
      )}
    </>
  )
}

const Container = styled.div`
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'flex-start' : 'center'};
  overflow: hidden;
`
const UsersContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100vw' : '1400px'};

  justify-content: space-between;
  gap: 30px;
  flex-wrap: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'nowrap' : 'wrap'};
  overflow-x: auto;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const ImageContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const Title = styled.div`
  font-family: 'JetBrains';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '94px' : '94px')};
  font-size: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '44px' : '44px')};
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '44px' : '50px'};
  color: #215663;
  margin-bottom: 20px;
  max-width: 1400px;
  width: 100%;
`
const Image = styled.img`
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '50vw')};
  height: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '236px' : '576px')};
  object-fit: cover;
  margin-bottom: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
`
const Text = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  letter-spacing: 0.03em;
  width: 100%;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100vw' : '700px'};

  color: #5e8a91;
  margin-bottom: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '30px' : '60px'};
  opacity: ${({ opacity }) => opacity};

  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
`

const PartnersTitle = styled.div`
  font-family: 'JetBrains';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
  color: #b1c7cb;
  margin-bottom: 30px;
  margin-top: 30px;
`

const TopLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
`

export default Team
