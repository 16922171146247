import React, { Children } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import cityMap from '../assets/cityMap.png'
import { ReactComponent as Logo } from '../logo.svg'
import HeaderItem from '../Components/Header/HeaderItem'

const Footer = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  return (
    <>
      <Container id={'contacts'} isTabletOrMobile={isTabletOrMobile}>
        <ContentContainer isTabletOrMobile={isTabletOrMobile}>
          <TechnologiesContainer isTabletOrMobile={isTabletOrMobile}>
            <TitleContainer isTabletOrMobile={isTabletOrMobile}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: 45,
                }}
              >
                <Title isTabletOrMobile={isTabletOrMobile}>PALESSIT</Title>
                <Logo style={{ height: isTabletOrMobile ? 20 : 40 }} />
              </div>

              <Info isTabletOrMobile={isTabletOrMobile}>
                Резидент Парка Высоких Технологий.
              </Info>
              <Info isTabletOrMobile={isTabletOrMobile}>
                Свидетельство №0000681
              </Info>
            </TitleContainer>

            <ContactsContainer isTabletOrMobile={isTabletOrMobile}>
              <LinkItems isTabletOrMobile={isTabletOrMobile}>
                <HeaderItem
                  margin
                  to={'about'}
                  title='О компании'
                  color={'#2A2F36'}
                />
                <HeaderItem
                  margin
                  to={'work'}
                  title='Что мы делаем'
                  color={'#2A2F36'}
                />
                <HeaderItem
                  margin
                  to={'technologies'}
                  title='Технологии'
                  color={'#2A2F36'}
                />
                <HeaderItem
                  margin
                  to={'team'}
                  title='Команда'
                  color={'#2A2F36'}
                />
                <HeaderItem
                  margin
                  to={'partners'}
                  title='Сотрудничество'
                  color={'#2A2F36'}
                />
                <HeaderItem
                  margin
                  to={'contacts'}
                  title='Контакты'
                  color={'#2A2F36'}
                />
              </LinkItems>
            </ContactsContainer>
          </TechnologiesContainer>
        </ContentContainer>
      </Container>

      {/* <Container isTabletOrMobile={isTabletOrMobile}> */}
      <ContentContainer isTabletOrMobile={isTabletOrMobile}>
        <TopLine isTabletOrMobile={isTabletOrMobile} />
        <TechnologiesContainer
          style={{ marginTop: 0 }}
          isTabletOrMobile={isTabletOrMobile}
        >
          <Info
            style={{ color: '#B1C7CB', paddingBottom: 25, paddingTop: 25 }}
            isTabletOrMobile={isTabletOrMobile}
          >
            2021 Palessit. All right reserved.
          </Info>
        </TechnologiesContainer>
      </ContentContainer>
      {/* </Container> */}
    </>
  )
}

const LinkItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 198px;
  padding-top: 45px;
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '595px')};
  overflow: hidden;
  border-right: 1px solid #b1c7cb80;
`

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 690px;
  padding-left: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '20px' : '33px'};
  box-sizing: border-box;
  width: 100%;
`

const Info = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2a2f36;
  padding-right: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  box-sizing: border-box;
  align-items: start;
  width: 100%;
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '1400px')};
`

const Container = styled.div`
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'flex-start' : 'center'};
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const TechnologiesContainer = styled.div`
  display: flex;
  flex-direction: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'column-reverse' : 'row'};
  width: 100%;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100%' : '1400px'};

  overflow: hidden;
`

const Title = styled.div`
  display: flex;
  box-sizing: border-box;
  font-family: 'Azeret Mono';
  font-weight: 700;
  font-size: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '40px' : '44px')};
  padding-left: 20px;
  /* padding-right: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '20px' : '110px'}; */
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '44px' : '50px'};
  color: #2a2f36;
`

const TopLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
`

export default Footer
