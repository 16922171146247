import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

const Partners = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <Container id={'partners'} isTabletOrMobile={isTabletOrMobile}>
      <ContentContainer isTabletOrMobile={isTabletOrMobile}>
        <Title isTabletOrMobile={isTabletOrMobile}>Сотрудничество</Title>
        <Text isTabletOrMobile={isTabletOrMobile}>
          Мы поставили десятки продуктов, внутренних и для клиентов по всему
          миру. Большинство из них оптимизированы для мобильных устройств и
          ориентированы на мобильные устройства.
        </Text>
        <TopLine isTabletOrMobile={isTabletOrMobile} />

        {isTabletOrMobile && (
          <Map
            isTabletOrMobile={isTabletOrMobile}
            src={require('../assets/images/Sotrudnichestvo.png')}
            alt='htp'
          />
        )}
        <TechnologiesContainer isTabletOrMobile={isTabletOrMobile}>
          <div style={{ maxWidth: 690 }}>
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              наши партнеры
            </WorkTitle>
            <InfoContainer isTabletOrMobile={isTabletOrMobile}>
              <Image
                isTabletOrMobile={isTabletOrMobile}
                src={require('../assets/partners/htp.png')}
                alt='htp'
              />
              <Image
                isTabletOrMobile={isTabletOrMobile}
                src={require('../assets/partners/bsu.png')}
                alt='bsu'
              />
              <Image
                isTabletOrMobile={isTabletOrMobile}
                src={require('../assets/partners/ama.png')}
                alt='ama'
              />
              <Image
                isTabletOrMobile={isTabletOrMobile}
                src={require('../assets/partners/statista.png')}
                alt='statista'
              />
              <Image
                isTabletOrMobile={isTabletOrMobile}
                src={require('../assets/partners/bsu2.png')}
                alt='bsu2'
              />
            </InfoContainer>
          </div>
          {!isTabletOrMobile && (
            <VerticalLine isTabletOrMobile={isTabletOrMobile} />
          )}
          {!isTabletOrMobile && (
            <Map
              isTabletOrMobile={isTabletOrMobile}
              src={require('../assets/images/Sotrudnichestvo.png')}
              alt='htp'
            />
          )}
        </TechnologiesContainer>
      </ContentContainer>
    </Container>
  )
}

const Container = styled.div`
  background: #ebf4f5;
  display: flex;
  flex-direction: column;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'flex-start' : 'center'};
`
const WorkTitle = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 30px;
  text-transform: uppercase;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '0' : '60px')};
  box-sizing: border-box;
  box-sizing: border-box;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const TechnologiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'column' : 'row'};
  width: 100%;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100vw' : '1400px'};
`

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '' : '623px')};
  max-width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '480px')};
  box-sizing: border-box;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
`

const Title = styled.div`
  font-family: 'JetBrains';
  font-weight: 700;
  margin-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '94px' : '94px')};
  font-size: 24px;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;

  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '44px' : '50px'};
  color: #2a2f36;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1400px;
`
const Image = styled.img`
  max-width: 100%;
  max-height: 80px;
  object-fit: contain;
  margin-bottom: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
`
const Text = styled.div`
  font-family: 'JetBrains';
  width: 100%;
  max-width: 1400px;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #5e8a91;
  margin-bottom: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '30px' : '60px'};
  opacity: ${({ opacity }) => opacity};
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
`

const TopLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
`
const VerticalLine = styled.div`
  flex: 1;
  max-width: 1px;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
`

const Map = styled.img`
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '690px')};
  height: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '320px' : '623px')};
  object-fit: contain;
  margin-bottom: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};

  box-sizing: border-box;
`

export default Partners
