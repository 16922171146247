import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'

const Work = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })

  return (
    <Container id='work' isTabletOrMobile={isTabletOrMobile}>
      <ContentContainer isTabletOrMobile={isTabletOrMobile}>
        <Title isTabletOrMobile={isTabletOrMobile}>Что мы делаем</Title>
        <Text isTabletOrMobile={isTabletOrMobile} style={{ color: '#5E8A91' }}>
          Использование своих возможностей для расширения возможностей бизнеса
        </Text>
        <TopLine isTabletOrMobile={isTabletOrMobile} />
        <InfoContainer isTabletOrMobile={isTabletOrMobile}>
          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              веб-приложения
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              React SPA приложения
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Server Render (SSR) для SEO
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Pagespeed и Webpagetest результаты
            </WorkItem>
          </WorkBlock>

          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              Cерверные приложения
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Serverless, разработка микросервисов
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              OpenAPI документирование
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Взаимодействие с SQL / NoSQL
            </WorkItem>
          </WorkBlock>

          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              мобильные приложения
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Кросс-платформенные React Native приложения
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Гибридные приложения (React, HTML 5)
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Кросс-платформенные Expo приложения
            </WorkItem>
          </WorkBlock>

          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              Оптимизация инфраструктуры
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Миграция в Docker, Kubernetes, Cloudworkers
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Миграция в cloud, scaling and security
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Workflow setup (бэкапы, CI/CD, staging)
            </WorkItem>
          </WorkBlock>

          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              SEO И оптимизация
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Улучшение результатов в Pagespeed и Webpagetest
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Opengraph, Schema, analytics, robots, sitemap..
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>SEO</WorkItem>
          </WorkBlock>

          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              UI/UX Дизайн
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              UI/UX (ре)дизайн
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>Брендинг</WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Юзабилити тестирование
            </WorkItem>
          </WorkBlock>

          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              Облачные технологии
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Microsoft Azure
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>Figma</WorkItem>
          </WorkBlock>

          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              {`Тестирование & QA`}
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Модульные тесты для UI компонентов и точек API
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Интегрированное тестирование и QA
            </WorkItem>
          </WorkBlock>
          <WorkBlock
            isTabletOrMobile={isTabletOrMobile}
            isBigScreen={isBigScreen}
          >
            <WorkTitle isTabletOrMobile={isTabletOrMobile}>
              Интеграции
            </WorkTitle>

            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Платежные сервисы, социальные сети
            </WorkItem>
            <WorkItem isTabletOrMobile={isTabletOrMobile}>
              Логи, Бэкапы, CDN
            </WorkItem>
          </WorkBlock>
        </InfoContainer>
        <BottomLine isTabletOrMobile={isTabletOrMobile} />
      </ContentContainer>
    </Container>
  )
}

const WorkItem = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15 }}>
      <Dot />
      <Info isTabletOrMobile={isTabletOrMobile}>{children}</Info>
    </div>
  )
}

const Container = styled.div`
  height: 100%;
  background: #f6f9fa;
  display: flex;
  flex-direction: column;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'flex-start' : 'center'};
`
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
`

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'nowrap' : 'wrap'};
  overflow-x: auto;
  justify-content: space-between;
  justify-content: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'start' : 'center'};
  height: ${({ isTabletOrMobile }) => isTabletOrMobile && '353px'};
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100%' : '1400px'};
`

const Title = styled.div`
  font-family: 'JetBrains';
  font-weight: 700;
  margin-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '94px' : '94px')};
  font-size: 24px;
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '36px' : '50px'};
  color: #2a2f36;
  margin-bottom: 20px;
  width: 100%;
  max-width: 1400px;
`
const WorkBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 220px;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100%' : 'calc(1400px / 3)'};
  padding-top: 25px;
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-bottom: 30px;
  box-sizing: border-box;
  flex-shrink: 0;
`

const WorkTitle = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
  color: #2a2f36;
  margin-bottom: 30px;
`
const Text = styled.div`
  font-family: 'JetBrains';
  width: 100%;
  max-width: 1400px;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #12c0dd;
  margin-bottom: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '30px' : '60px'};
  opacity: ${({ opacity }) => opacity};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
`
const Dot = styled.div`
  display: flex;
  flex-shrink: 0;
  background: #b1c7cb;
  width: 16px;
  height: 16px;
  border-radius: 8px;
`
const Info = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2a2f36;
  padding-right: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-end'};
`

const TopLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-bottom: 20px;
`
const BottomLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-top: 30px;
  margin-bottom: 150px;
`

export default Work
