import { createGlobalStyle } from 'styled-components'
import AzeretMono from '../assets/AzeretMono-Regular.ttf'
import JetBrains from '../assets/JetBrainsMono-Bold.woff2'
import FiraSans from '../assets/FiraSans-Regular.ttf'
import FiraSansSemibold from '../assets/FiraSans-SemiBold.ttf'

const FontStyles = createGlobalStyle`

@font-face {
  font-family: 'JetBrains';
  src: local('JetBrains'), url(${JetBrains}) format('woff2');
}
@font-face {
  font-family: 'Azeret Mono';
  src: local('Azeret Mono'), url(${AzeretMono}) format('truetype');
}
@font-face {
  font-family: 'Fira Sans';
  src: local('Fira Sans'), url(${FiraSans}) format('truetype');
}
@font-face {
  font-family: 'Fira Sans Semi Bold ';
  src: local('Fira Sans Semi Bold'), url(${FiraSansSemibold}) format('truetype');
}
`

export default FontStyles
