import React, { useState } from 'react'
import styled from 'styled-components'
import FontStyles from '../../styles/FontsStyles'
import { ReactComponent as Logo } from '../../logo.svg'
import { ReactComponent as Burger } from '../../assets/icons/menu.svg'
import HeaderItem from './HeaderItem'
import { useMediaQuery } from 'react-responsive'
import SideMenu from '../SideMenu/SideMenu'

const Header = (props) => {
  const [show, setShow] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <HeaderContainer isTabletOrMobile={isTabletOrMobile}>
      <FontStyles />
      <ContentContainer isTabletOrMobile={isTabletOrMobile}>
        <LogoContainer>
          <Title isTabletOrMobile={isTabletOrMobile}>PALESSIT</Title>{' '}
          <Logo style={{ height: isTabletOrMobile ? 20 : 40 }} />
        </LogoContainer>
        {isTabletOrMobile ? (
          <Burger
            onClick={() => setShow(true)}
            style={{ marginRight: 24, boxSizing: 'border-box' }}
          />
        ) : (
          <div style={{ display: 'flex' }}>
            <HeaderItem to={'about'} title='О компании' />
            <HeaderItem to={'work'} title='Что мы делаем' />
            <HeaderItem to={'technologies'} title='Технологии' />
            <HeaderItem to={'team'} title='Команда' />
            <HeaderItem to={'partners'} title='Сотрудничество' />
            <HeaderItem to={'contacts'} title='Контакты' />
          </div>
        )}
      </ContentContainer>
      {isTabletOrMobile && <SideMenu show={show} setShow={setShow} />}
    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  height: 60px;
  width: 100%;
  background-color: #2a2f36;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #b1c7cb80;
`
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 1400px;
  box-sizing: border-box;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
`

const Title = styled.div`
  font-family: 'Azeret Mono';
  font-style: normal;
  font-weight: 400;
  font-size: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '17.7947px' : '36px'};
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '20.77px' : '42px'};
  letter-spacing: 0.06em;
  color: #ffffff;
`

export default Header
