import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-scroll'

const HeaderItem = ({ title, margin, ...props }) => {
  const [active, setActive] = useState(false)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })

  return (
    <Container
      isTabletOrMobile={isTabletOrMobile}
      to={props.to}
      spy={true}
      smooth={true}
      onSetActive={() => setActive(true)}
      onSetInactive={() => setActive(false)}
      active={active}
      margin={margin}
    >
      <Title isTabletOrMobile={isTabletOrMobile} color={props.color}>
        {title}
      </Title>
    </Container>
  )
}

const Container = styled(Link)`
  display: flex;
  height: 33px;
  border-bottom: ${({ isTabletOrMobile, active, color }) =>
    active && ((isTabletOrMobile && '2px solid white') || '2px solid #12c0dd')};
  margin-right: 30px;
  margin-bottom: ${({ isTabletOrMobile, margin }) =>
    isTabletOrMobile || margin ? '30px' : '0'};
  cursor: pointer;
`

const Title = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: ${({ isTabletOrMobile, color }) =>
    color ? color : isTabletOrMobile ? 'white' : '#12c0dd'};
`

export default HeaderItem
