import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { ReactComponent as Phone } from '../assets/icons/phone.svg'
import { ReactComponent as Location } from '../assets/icons/pin.svg'
import { ReactComponent as Email } from '../assets/icons/email.svg'

const Contacts = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  return (
    <Container id={'contacts'} isTabletOrMobile={isTabletOrMobile}>
      <ContentContainer isTabletOrMobile={isTabletOrMobile}>
        <TechnologiesContainer isTabletOrMobile={isTabletOrMobile}>
          <Image
            isTabletOrMobile={isTabletOrMobile}
            src={require('../assets/images/map.png')}
            alt='htp'
          />

          <ContactsContainer>
            <Title isTabletOrMobile={isTabletOrMobile}>Контакты</Title>
            {!isTabletOrMobile && (
              <TopLine isTabletOrMobile={isTabletOrMobile} />
            )}

            <InfoContainer isTabletOrMobile={isTabletOrMobile}>
              <WorkBlock isTabletOrMobile={isTabletOrMobile}>
                <Location
                  style={{
                    height: isTabletOrMobile ? 20 : 40,
                  }}
                />
                <WorkTitle isTabletOrMobile={isTabletOrMobile}>Офис</WorkTitle>

                <Info isTabletOrMobile={isTabletOrMobile}>
                  Беларусь, Минск,
                </Info>
                <Info isTabletOrMobile={isTabletOrMobile}>
                  переулок Броневой, 5
                </Info>
              </WorkBlock>
              <WorkBlock isTabletOrMobile={isTabletOrMobile}>
                <Phone style={{ height: isTabletOrMobile ? 20 : 40 }} />
                <WorkTitle isTabletOrMobile={isTabletOrMobile}>
                  телефон
                </WorkTitle>

                <Info isTabletOrMobile={isTabletOrMobile}>
                  +375 (29) 7777-29-7
                </Info>
              </WorkBlock>
              <WorkBlock isTabletOrMobile={isTabletOrMobile}>
                <Email style={{ height: isTabletOrMobile ? 20 : 40 }} />
                <WorkTitle isTabletOrMobile={isTabletOrMobile}>Email</WorkTitle>

                <Info isTabletOrMobile={isTabletOrMobile}>
                  info@palessit.com
                </Info>
              </WorkBlock>

              <WorkBlock
                isTabletOrMobile={isTabletOrMobile}
                isBigScreen={isBigScreen}
              >
                <Info isTabletOrMobile={isTabletOrMobile}>
                  ООО «Палессит» УНП 193058395
                </Info>
                <Info isTabletOrMobile={isTabletOrMobile}>УНП 193058395</Info>
                <Info isTabletOrMobile={isTabletOrMobile}>
                  IBAN: BY56PJCB30120597091000000933
                </Info>
                <Info isTabletOrMobile={isTabletOrMobile}>
                  Приорбанк ОАО, г.Минск, ул.Тимирязева 65а
                </Info>
              </WorkBlock>
            </InfoContainer>
            <BottomLine isTabletOrMobile={isTabletOrMobile} />
          </ContactsContainer>
        </TechnologiesContainer>
      </ContentContainer>
    </Container>
  )
}

const WorkBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 100%;
  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '49%' : 'calc(690px / 2)'};
  padding-top: 25px;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-bottom: 30px;
`
const Title = styled.div`
  font-family: 'JetBrains';
  font-weight: 700;
  margin-top: 50px;
  font-size: 24px;
  box-sizing: border-box;
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '36px' : '50px'};
  margin-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  color: white;
`

const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 690px;
  width: 100%;
  align-self: center;
`

const Info = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: white;
  word-wrap: break-word;
  /* padding-right: 20px; */
  box-sizing: border-box;
  /* padding-left: 20px; */
  box-sizing: border-box;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-end'};
`

const Container = styled.div`
  /* height: 850px; */
  overflow: hidden;
  background: #2a2f36;
  display: flex;
  flex-direction: column;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'flex-start' : 'space-between'};
`
const WorkTitle = styled.div`
  font-family: 'Fira Sans';
  color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-transform: uppercase;
  padding-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  margin-bottom: 8px;
  box-sizing: border-box;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const TechnologiesContainer = styled.div`
  display: flex;
  flex-direction: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'column-reverse' : 'row'};
  width: 100%;
  justify-content: space-between;

  max-width: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '100vw' : '1400px'};
`
const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  width: 100%;
  max-width: 700px;
  padding-left: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  box-sizing: border-box;
  box-sizing: border-box;
`

const Image = styled.img`
  height: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '377px' : '855px')};
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '595px')};
  object-fit: cover;
  border-right: 1px solid #b1c7cb80;
`

const TopLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-bottom: 60px;
  margin-top: 60px;
`
const VerticalLine = styled.div`
  flex: 1;
  max-width: 1px;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
`
const BottomLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  /* margin-top: 30px; */
  margin-bottom: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '0' : '60px')};
`

export default Contacts
