import React from 'react'
import styled from 'styled-components'

const PersonCard = ({ image, name, position }) => {
  return (
    <Container src={image}>
      <NameContainer>
        <Name>{name}</Name>
        <Position>{position}</Position>
      </NameContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 180px;
  height: 260px;
  background-image: ${({ src }) => `url(${src})`};
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }
`

const NameContainer = styled.div`
  width: 180px;
  height: 80px;
  background: #5e8a91;
  padding: 10px;
  box-sizing: border-box;
  opacity: 0;
  transition: 0.3s;
  ${Container}:hover & {
    opacity: 1;
  }
`

const Name = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #ffffff;
`

const Position = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #ffffff;
`

export default PersonCard
