import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'

const About = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  return (
    <Container id='about' isTabletOrMobile={isTabletOrMobile}>
      <InfoContainer isTabletOrMobile={isTabletOrMobile}>
        <TextContainer isTabletOrMobile={isTabletOrMobile}>
          <Title isTabletOrMobile={isTabletOrMobile}>
            Palessit — it-компания <br /> полного цикла из Беларуси
          </Title>
          <Text
            isTabletOrMobile={isTabletOrMobile}
            style={{ color: '#5E8A91' }}
          >
            Разрабатываем инновационные IT-решения
          </Text>
        </TextContainer>

        <TopLine isTabletOrMobile={isTabletOrMobile} />
        <TextContainer isTabletOrMobile={isTabletOrMobile}>
          {!!isTabletOrMobile && (
            <Image
              isTabletOrMobile={isTabletOrMobile}
              src={require('../assets/images/about.jpg')}
              alt='about'
            />
          )}
          <Info isTabletOrMobile={isTabletOrMobile}>
            Начиная самостоятельную разработку, реализуя все процессы внутри
            одной команды мы понимали проблемы и сложности с которыми придется
            столкнуться. Метод реализации IT-проекта собственными силами, без
            привлечения сторонних специалистов и компаний не самый простой и не
            самый быстрый. Но наиболее надежный и основательный! Одной идеи
            мало! Надо знать как и с кем ее реализовать. Наши принципы “know
            how” и “know who”, это то, чего не хватает большинству новых
            проектов. Чувствуя поддержку и уверенность друг друга, мы не боимся
            взяться за глобальные надиндустриальные проекты, продукты на
            создание которого не решились более крупные и более опытные
            компании.
          </Info>
        </TextContainer>
        <BottomLine isTabletOrMobile={isTabletOrMobile} />
      </InfoContainer>
      {!isTabletOrMobile && (
        <Image
          isTabletOrMobile={isTabletOrMobile}
          src={require('../assets/images/about.jpg')}
          alt='about'
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-direction: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'column' : 'row'};
  align-items: center;
  justify-content: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-end'};
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-end'};
  max-width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '720px')};
  width: 100%;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-right: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '0px' : '90px'};
  padding-right: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '20px' : '0')};
  padding-left: 20px;
  box-sizing: border-box;
`

const Title = styled.div`
  font-family: 'JetBrains';
  font-weight: 700;
  margin-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '94px' : '94px')};
  font-size: 24px;
  box-sizing: border-box;
  line-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '36px' : '50px'};
  color: #2a2f36;
  margin-bottom: 20px;
`
const Text = styled.div`
  font-family: 'JetBrains';
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #12c0dd;
  margin-bottom: 30px;
  opacity: ${({ opacity }) => opacity};
  box-sizing: border-box;
`
const Info = styled.div`
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #2a2f36;
  align-items: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? 'center' : 'flex-start'};
`

const BottomLine = styled.div`
  height: 1px;
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '50vw')};
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-top: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '30px' : '83px')};
  margin-bottom: 30px;
`
const TopLine = styled.div`
  height: 1px;
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '50vw')};
  opacity: 0.5;
  background-color: #b1c7cb;
  display: flex;
  margin-bottom: 30px;
`

const Image = styled.img`
  width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? '100%' : '50%')};
  max-height: ${({ isTabletOrMobile }) =>
    isTabletOrMobile ? '280px' : '100vh'};
  object-fit: cover;
  box-sizing: border-box;
`

export default About
