import { useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import Header from './Components/Header/Header'
import About from './Pages/About'
import Contacts from './Pages/Contacts'
import Footer from './Pages/Footer'
import Main from './Pages/Main'
import Partners from './Pages/Partners'
import Team from './Pages/Team'
import Technologies from './Pages/Technologies'
import Work from './Pages/Work'

function App() {
  return (
    <>
      <Header />
      <div>
        <Main />
        <About />
        <Work />
        <Technologies />
        <Team />
        <Partners />
        <Contacts />
        <Footer />
      </div>
    </>
  )
}

export default App
