import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Close } from '../../assets/icons/close.svg'
import { ReactComponent as Logo } from '../../logo.svg'
import HeaderItem from '../Header/HeaderItem'

const SideMenu = (props) => {
  return (
    <Container show={props.show}>
      <Menu>
        <LogoContainer>
          <TitleContainer>
            <Title>PALESSIT</Title> <Logo style={{ height: 20 }} />
          </TitleContainer>

          <Close
            style={{ height: 20, cursor: 'pointer', marginRight: 20 }}
            onClick={() => props.setShow(false)}
          />
        </LogoContainer>
        <HeaderItem to={'about'} title='О компании' />
        <HeaderItem to={'work'} title='Что мы делаем' />
        <HeaderItem to={'technologies'} title='Технологии' />
        <HeaderItem to={'team'} title='Команда' />
        <HeaderItem to={'partners'} title='Сотрудничество' />
        <HeaderItem to={'contacts'} title='Контакты' />
      </Menu>
      <BottomLine />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  background: #5e8a91;
  padding: 30px 0 20px 0;
  box-sizing: border-box;
  overflow: hidden;
  width: ${({ show }) => (show ? '235px' : 0)};
  transition: 0.3s;
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
`
const Title = styled.div`
  font-family: 'Azeret Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 17.7947px;
  line-height: 20.77px;
  letter-spacing: 0.06em;
  color: #ffffff;
`
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`
const BottomLine = styled.div`
  height: 1px;
  width: 215px;
  opacity: 0.5;
  background-color: white;
  display: flex;
  margin-bottom: 160px;
  margin-left: 20px;
`

export default SideMenu
